<template>
  <div class="check-list">
    <CModal
      v-if="isShowModal"
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h6>Check List</h6>
        <CButtonClose @click="modalCallBack(false)" />
      </template>
      <template #footer>
        <CButton color="secondary" @click="modalCallBack(false)">Close</CButton>
      </template>
      <LoadingOverlay v-if="isFetching" />
      <div v-else>
        <CTabs :activeTab="tabIndex" @update:activeTab="changeActiveTab">
          <CTab :title="`Document Vault [${getUploadedDocument.length}]`">
            <div class="table-responsive">
              <table class="table table-striped w-100">
                <colgroup>
                  <col span="1" style="width: 20%" />
                  <col span="1" style="width: 40%" />
                  <col span="1" style="width: 20%" />
                  <col span="1" style="width: 20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Preview</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data in getUploadedDocument"
                    :key="`document_${data.candidate_document_id}`"
                  >
                    <td>
                      {{ data.document_type.comments || "--" }}
                    </td>
                    <td>
                      {{ data.document_name || "--" }}
                    </td>
                    <td>
                      <a
                        type="button"
                        @click="preview(data)"
                        class="p-0 hand-icon"
                      >
                        <i class="fas fa-eye ml-3"></i>
                      </a>
                    </td>
                    <td>
                      <button
                        class="action-button"
                        @click="fileDownload(data, data.document_type_id)"
                      >
                        <i class="fas fa-download ml-4"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="!getUploadedDocument.length">
                    <td colspan="4">
                      <p class="text-muted h6 mt-2 text-center">
                        No Data Found
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CTab>
          <CTab :title="`Doc Log`">
            <CListGroup>
              <CListGroupItem
                href="javascript:"
                @click="showCollapse('pre_offer')"
                ><h6>
                  Pre-Offer [{{ `${_preOfferDocumentAction.length}` }}
                  {{ _preOfferDocumentAction | getRecord }}]
                </h6></CListGroupItem
              >
              <div
                class="bg-light p-1 border border-light"
                v-if="collapse === 'pre_offer'"
              >
                <DocumentActionTable
                  :documentActions="_preOfferDocumentAction"
                  :actionInfo="actionInfo"
                  action_type="pre_offer"
                  @preview="preview"
                  @addAdditionalDocs="addAdditionalDocs"
                  @initAction="initAction"
                />
              </div>
              <CListGroupItem href="javascript:" @click="showCollapse('offer')"
                ><h6>
                  Offer [{{ _offerDocumentAction.length }}
                  {{ _offerDocumentAction | getRecord }}]
                </h6></CListGroupItem
              >
              <div
                class="bg-light p-1 border border-light"
                v-if="collapse === 'offer'"
              >
                <DocumentActionTable
                  :documentActions="_offerDocumentAction"
                  :actionInfo="actionInfo"
                  action_type="offer"
                  @preview="preview"
                  @addAdditionalDocs="addAdditionalDocs"
                  @initAction="initAction"
                />
              </div>
              <CListGroupItem
                href="javascript:"
                @click="showCollapse('licensing')"
                ><h6>
                  Licensing [{{ _licensingDocumentAction.length }}
                  {{ _licensingDocumentAction | getRecord }}]
                </h6></CListGroupItem
              >
              <div
                class="bg-light p-1 border border-light"
                v-if="collapse === 'licensing'"
              >
                <DocumentActionTable
                  :documentActions="_licensingDocumentAction"
                  :actionInfo="actionInfo"
                  action_type="licensing"
                  @preview="preview"
                  @addAdditionalDocs="addAdditionalDocs"
                  @initAction="initAction"
                />
              </div>
              <CListGroupItem href="javascript:" @click="showCollapse('visa')"
                ><h6>
                  Visa [{{ _visaDocumentAction.length }}
                  {{ _visaDocumentAction | getRecord }}]
                </h6></CListGroupItem
              >
              <div
                class="bg-light p-1 border border-light"
                v-if="collapse === 'visa'"
              >
                <DocumentActionTable
                  :documentActions="_visaDocumentAction"
                  :actionInfo="actionInfo"
                  action_type="visa"
                  @preview="preview"
                  @addAdditionalDocs="addAdditionalDocs"
                  @initAction="initAction"
                />
              </div>
              <CListGroupItem
                href="javascript:"
                @click="showCollapse('joining')"
                ><h6>
                  Joining [{{ _joiningDocumentAction.length }}
                  {{ _joiningDocumentAction | getRecord }}]
                </h6></CListGroupItem
              >
              <div
                class="bg-light p-1 border border-light"
                v-if="collapse === 'joining'"
              >
                <DocumentActionTable
                  :documentActions="_joiningDocumentAction"
                  :actionInfo="actionInfo"
                  action_type="joining"
                  @preview="preview"
                  @addAdditionalDocs="addAdditionalDocs"
                  @initAction="initAction"
                />
              </div>
              <CListGroupItem
                href="javascript:"
                @click="showCollapse('all_docs')"
                ><h6>
                  All Documents [{{ _allDocumentAction.length }}
                  {{ _allDocumentAction | getRecord }}]
                </h6></CListGroupItem
              >
              <div
                class="bg-light p-1 border border-light"
                v-if="collapse === 'all_docs'"
              >
                <DocumentActionTable
                  :documentActions="_allDocumentAction"
                  :actionInfo="actionInfo"
                  action_type="all_docs"
                  @preview="preview"
                  :showAddButton="false"
                  :showDeleteButton="false"
                  @initAction="initAction"
                />
              </div>
            </CListGroup>
          </CTab>
          <CTab :title="`Interview Assessment`">
            <InterviewAssessment :interviewFeedBacks="_interviewFeedbacks" />
          </CTab>
          <CTab :title="`Pre-Screening Questions`">
            <div class="m-3">
              <PreScreeningTransaction
                v-if="preScreeningTransactions.length"
                :preScreeningTransactions="preScreeningTransactions"
              />
              <p v-else class="text-muted text-center mt-3 h6">No Data Found</p>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
    <UploadDocumentAction
      v-if="uploadDocActionModal.isShowPopup"
      :isShowPopup="
        uploadDocActionModal.isShowPopup && !previewModal.isShowPopup
      "
      :action_type="uploadDocActionModal.action_type"
      :candidateDetails="candidateDetails"
      :jobDetails="jobDetails"
      :actionInfo="actionInfo"
      @modalCallBack="uploadDocActionModalCallBack"
      @preview="preview"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
      v-if="previewModal.isShowPopup"
    />
  </div>
</template>
<script>
import InterviewFeedBack from "@/components/reusable/InterviewFeedBack";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import PreviewModal from "@/components/reusable/PreviewModal";
import { BASE_URL } from "@/service_urls";
import pdf from "vue-pdf";
import { mapActions, mapGetters } from "vuex";
import DocumentActionTable from "@/components/reusable/CheckList/DocumentActionTable";
import InterviewAssessment from "@/components/reusable/CheckList/InterviewAssessment";
import UploadDocumentAction from "@/components/reusable/CheckList/UploadDocumentAction";
import PreScreeningTransaction from "@/components/reusable/PreScreeningTransaction";
import m from "moment";
export default {
  name: "checkList",
  props: {
    isShowPopup: {
      type: Boolean,
      default: true,
    },
    candidateDetails: {
      type: Object,
      default: () => {},
    },
    jobDetails: {
      type: Object,
      default: () => {},
    },
    actionInfo: {
      type: Object,
      default: () => {},
    },
    isActionCompleted: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    accordion: {
      type: String,
      default: null,
    },
  },
  components: {
    InterviewFeedBack,
    PreviewModal,
    PreScreeningTransaction,
    // PreOffer,
    // Offer,
    // Licensing,
    // Visa,
    // Joining,
    InterviewAssessment,
    UploadDocumentAction,
    DocumentActionTable,
  },
  data() {
    return {
      tabIndex: 0,
      isFetching: false,
      collapse: this.accordion,
      iframe: {
        url: null,
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      uploadDocActionModal: {
        isShowPopup: false,
        action_type: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getUploadedDocument",
      "preOfferDocumentActions",
      "offerDocumentActions",
      "licensingDocumentActions",
      "getAllInterviewFeedback",
      "preScreeningTransactions",
      "visaDocumentActions",
      "joiningDocumentActions",
    ]),
    isShowModal() {
      return (
        !this.previewModal.isShowPopup && !this.uploadDocActionModal.isShowPopup
      );
    },
    _preOfferDocumentAction() {
      return this.combineReturnAction(this.preOfferDocumentActions);
    },
    _offerDocumentAction() {
      return this.combineReturnAction(this.offerDocumentActions);
    },
    _licensingDocumentAction() {
      return this.combineReturnAction(this.licensingDocumentActions);
    },
    _visaDocumentAction() {
      return this.combineReturnAction(this.visaDocumentActions);
    },
    _joiningDocumentAction() {
      return this.combineReturnAction(this.joiningDocumentActions);
    },
    _interviewFeedbacks() {
      const _base_domain = "https://" + window.location.host;
      return (
        this.getAllInterviewFeedback.map((v) => ({
          interviewer: v?.user
            ? `${v?.user?.first_name}  ${v?.user?.surname}`
            : `${v?.external_user?.first_name}  ${v?.external_user?.surname}`,
          interviewFeedback: v?.interview_feedback?.length
            ? v?.interview_feedback[0]
            : null,
          external_user: v?.external_user,
          external_user_id: v?.external_user_id,
          unique_url: v?.unique_url || null,
        })) || []
      );
    },
    _allDocumentAction() {
      return [
        ...this._preOfferDocumentAction,
        ...this._offerDocumentAction,
        ...this._licensingDocumentAction,
        ...this._visaDocumentAction,
        ...this._joiningDocumentAction,
      ];
    },
  },
  methods: {
    ...mapActions([
      "fetchUploadedDocumentbyCandidateId",
      "fetchAllInterviewFeedback",
      "downloadDocument",
      "fetchJobCandidateTransactions",
      "showToast",
      "getDocumentAction",
    ]),
    combineReturnAction(data) {
      let recruiterAction =
        data
          .filter(({ actioned_by }) => actioned_by === "recruiter")
          .map((v) => {
            let payload = {
              document_action_id: v?.document_action_id,
              comments: v?.comments,
              library_name: v?.document_library?.library_name,
              sent_date: v?.created_on,
              f_sent_date: this.formatDate(v?.created_on),
            };
            payload = {
              ...payload,
              type_name:
                v?.customer_document_type?.type_name ||
                v?.document_library?.customer_document_type?.type_name,
              document_name:
                v?.document_name ||
                v?.document_library?.organisation_document?.document_name,
              action_required: v?.action_required,
              link_to_doc:
                v?.link_to_doc ||
                v?.document_library?.organisation_document?.link_to_doc,
              data: v,
              ext:
                v?.document_ext?.document_ext ||
                v?.document_library?.organisation_document?.document_ext
                  ?.document_ext,
            };
            return payload;
          }) || [];
      let candidateAction =
        data
          .filter(({ actioned_by }) => actioned_by === "candidate")
          .map((v) => {
            let payload = {
              document_action_id: v?.document_action_id,
              previous_action_id: v?.previous_action_id,
              received_date: v?.created_on,
              f_received_date: this.formatDate(v?.created_on),
            };
            payload = {
              ...payload,
              type_name:
                v?.customer_document_type?.type_name ||
                v?.document_library?.customer_document_type?.type_name,
              document_name:
                v?.document_name ||
                v?.document_library?.organisation_document?.document_name,
              action_required: v?.action_required,
              link_to_doc:
                v?.link_to_doc ||
                v?.document_library?.organisation_document?.link_to_doc,
              data: v,
              ext:
                v?.document_ext?.document_ext ||
                v?.document_library?.organisation_document?.document_ext
                  ?.document_ext,
            };
            return payload;
          }) || [];
      recruiterAction.forEach((element, index) => {
        const { document_action_id } = element;
        const returnAction =
          candidateAction.filter(
            (v) => v?.previous_action_id === document_action_id
          ) || [];
        recruiterAction[index].returnAction = returnAction;
        recruiterAction[index].received_date = returnAction[0]?.received_date;
        recruiterAction[index].f_received_date =
          returnAction[0]?.f_received_date;
        recruiterAction[index].theme = element.action_required
          ? returnAction.length
            ? "inherit"
            : "#ffc8c8"
          : "inherit";
        recruiterAction[index].isPendingReturn = recruiterAction[index]
          ?.action_required
          ? !returnAction.length
          : false;
      });
      return recruiterAction || [];
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    preview(data) {
      const _base_domain = "https://" + window.location.host;
      const link_to_document = data?.uuid_link_to_doc
        ? appendAccessToken(`${_base_domain}${data.uuid_link_to_doc}`)
        : appendAccessToken(`${_base_domain}${data.link_to_doc}`);
      const ext = data?.ext || data?.document_ext?.document_ext;
      this.iframe.url = link_to_document;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(ext)) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    fileDownload(data) {
      let { uuid_link_to_doc, link_to_doc, document_name } = data;
      let url = uuid_link_to_doc
        ? uuid_link_to_doc.replace("/api/v1/", "")
        : link_to_doc.replace("/api/v1/", "");
      url = appendAccessToken(url);
      this.downloadDocument({ url, document_name });
    },
    showCollapse(data) {
      if (data === this.collapse) {
        this.collapse = null;
        return;
      }
      this.collapse = data;
    },
    formatDate(data, format = "DD MMM YYYY") {
      if (data) return m(data).format(format);
      return "--";
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    addAdditionalDocs(action_type) {
      this.uploadDocActionModal.isShowPopup = true;
      this.uploadDocActionModal.action_type = action_type;
    },
    async uploadDocActionModalCallBack(action, data) {
      if (action && data) {
        await this.$emit("uploadDocActionCallBack", data);
        // this.initAction();
        this.uploadDocActionModal.isShowPopup = false;
      } else this.uploadDocActionModal.isShowPopup = false;
    },
    initAction() {
      const { candidate_uid } = this.candidateDetails;
      const { job_id } = this.jobDetails;
      const { organisation_id } = this.actionInfo;
      let appendAction = [];
      appendAction = [
        ...appendAction,
        this.fetchUploadedDocumentbyCandidateId({
          customer_uid: this.getCustomerId,
          candidate_uid,
        }),
      ];
      appendAction = [
        ...appendAction,
        this.fetchAllInterviewFeedback({ job_id, candidate_uid }),
      ];
      appendAction = [
        ...appendAction,
        this.getDocumentAction({
          job_id,
          candidate_uid,
          all_rows: true,
          organisation_id,
          action_type__in: [
            "pre_offer",
            "offer",
            "licensing",
            "visa",
            "joining",
          ],
        }),
      ];
      appendAction = [
        ...appendAction,
        this.fetchJobCandidateTransactions({ job_id, candidate_uid }),
      ];

      this.isFetching = true;
      Promise.all(appendAction).then((res) => {
        this.isFetching = false;
      });
    },
    changeActiveTab(tab) {
      this.tabIndex = tab;
      this.collapse = null;
    },
  },
  mounted() {
    this.tabIndex = this.activeTab;
    this.initAction();
  },
  filters: {
    getRecord(data) {
      if (data) return data.length > 1 ? "Records" : "Record";
      return "";
    },
  },
  watch: {
    isActionCompleted(newVal, oldVal) {
      if (newVal) {
        this.initAction();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
tr.text-danger {
  td {
    color: inherit !important;
  }
}
</style>
