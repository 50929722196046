<template>
  <div class="offer">
    <div class="table-responsive bg-white border border-lignht">
      <table class="table table-striped w-100 mb-0">
        <colgroup>
          <col span="1" style="width: 30%" />
          <col span="1" :style="showDeleteButton ? 'width:10%' : 'width:15%'" />
          <col span="1" :style="showDeleteButton ? 'width:10%' : 'width:15%'" />
          <col span="1" style="width: 10%" />
          <col span="1" style="width: 10%" />
          <col span="1" style="width: 10%" />
          <col span="1" style="width: 10%" />
          <col span="1" style="width: 10%" v-if="showDeleteButton" />
        </colgroup>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>File Name</th>
            <th>Sent Date</th>
            <th>Response Required</th>
            <th>Candidate Return</th>
            <th>Received Date</th>
            <th v-if="showDeleteButton">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data, index) in documentActions"
            :key="index"
            :class="{ 'text-danger': data.isPendingReturn }"
          >
            <td>
              {{ data.type_name
              }}<i
                class="fa-solid fa-circle-info mx-1"
                v-c-tooltip="{ content: data.comments }"
              ></i>
            </td>
            <td>{{ data.library_name || "--" }}</td>
            <td>
              <CButton
                class="btn-link mb-1 cursor-pointer p-0 text-left"
                v-if="data && data.document_name && data.link_to_doc"
                @click="$emit('preview', data)"
              >
                {{ data.document_name }}
              </CButton>
              <p v-else-if="data && data.document_name">
                {{ data.document_name }}
              </p>
              <p v-else>--</p>
            </td>
            <td>{{ data.sent_date | formatDate }}</td>
            <td class="text-center">
              {{ data.action_required ? "Yes" : "No" }}
            </td>
            <td>
              <CButton
                class="btn-link mb-1 cursor-pointer p-0 text-left"
                v-for="returnAction in data.returnAction"
                @click="$emit('preview', returnAction)"
                :key="returnAction.action_id"
              >
                {{ returnAction.document_name }}
              </CButton>
              <p v-if="!data.returnAction.length">--</p>
            </td>
            <td>{{ data.received_date | formatDate }}</td>
            <td v-if="showDeleteButton">
              <a
                class="btn cursor-pointer align-center text-danger"
                v-if="data"
                @click="deleteDocumentActionModal(data)"
              >
                <i class="icon fas fa-trash ml-1"></i>
              </a>
            </td>
          </tr>
          <tr v-if="!documentActions.length">
            <td :colspan="showDeleteButton ? 8 : 7">
              <p class="text-muted h6 text-center mt-2">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CButton
      v-if="isShowAddButton && showAddButton"
      class="mt-2 mb-2"
      color="primary"
      @click="$emit('addAdditionalDocs', action_type)"
    >
      Add
    </CButton>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
      v-if="deleteConfirmModal.isShowPopup"
    />
  </div>
</template>
<script>
import m from "moment";
import Modal from "@/components/reusable/Modal";
import { mapActions } from "vuex";
export default {
  name: "offer",
  props: {
    documentActions: {
      type: Array,
      default: () => [],
    },
    actionInfo: {
      type: Object,
      default: () => {},
    },
    action_type: {
      type: String,
      default: "",
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Confirmation",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    isShowAddButton() {
      const { status_id, sub_status_id } = this.actionInfo;
      const ids = {
        pre_offer: {
          showAll: true,
        },
        offer: {
          status_id: [54, 58],
          sub_status_id: [57, 59, 60],
        },
        licensing: {
          status_id: [58, 62],
          sub_status_id: [60, 69],
        },
        visa: {
          status_id: [58, 62],
          sub_status_id: [60, 69, 63],
        },
        joining: {
          status_id: [58, 62],
          sub_status_id: [60, 69, 63],
        },
      };
      const statusObj = ids[this.action_type];
      return (
        statusObj?.showAll ||
        (statusObj &&
          [...statusObj.status_id].includes(status_id) &&
          [...statusObj.sub_status_id].includes(sub_status_id)) ||
        false
      );
    },
  },
  methods: {
    ...mapActions(["deleteDocumentAction"]),
    deleteDocumentActionModal(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${
        data?.type_name
      }${data?.library_name ? " | " + data.library_name : ""} ?`;
      this.deleteConfirmModal.data = data.document_action_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        let document_action_id = this.deleteConfirmModal.data;
        let payload = {
          document_action_id,
        };
        let { organisation_id } = this.actionInfo;
        if (!organisation_id)
          payload = {
            ...payload,
            organisation_id: this.actionInfo?.organisation_id,
          };
        this.deleteDocumentAction(payload).then(() => {
          this.$emit("initAction");
        });
      }
      this.deleteConfirmModal.selectedData = {};
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
  filters: {
    formatDate(data) {
      if (data) return m(data).format("DD MMM YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
tr.text-danger {
  td {
    color: inherit !important;
  }
}
</style>