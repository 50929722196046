<template>
  <div>
    <div v-show="!cropperModal.isShowPopup">
      <CModal
        color="primary"
        :show.sync="isShowPopup"
        :closeOnBackdrop="false"
        size="xl"
      >
        <template #header>
          <h6>{{ action_type | toPascal }} Action</h6>
          <CButtonClose @click="modalCallBack(false)" />
        </template>
        <template #footer>
          <CButton color="secondary" @click="modalCallBack(false)"
            >Close</CButton
          >
          <CButton color="primary" @click="onSubmit()">Send & Submit</CButton>
        </template>
        <LoadingOverlay v-if="isFetching" />
        <div v-else>
          <div class="table-responsive">
            <table class="table w-100">
              <colgroup>
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 10%" />
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Request Type</th>
                  <th>Response Required</th>
                  <th>File Name</th>
                  <th>Send</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in documentLibraries"
                  :key="data.document_library_id"
                >
                  <td>
                    <span>{{ data.type_name }}</span>
                    <i
                      class="fa-solid fa-circle-info mx-1"
                      v-c-tooltip="{ content: data.comments }"
                    />
                  </td>
                  <td>{{ data.library_name || "--" }}</td>
                  <td>{{ data.request_type | toPascal }}</td>
                  <td class="text-center align-middle">
                    <input
                      name="sign_req"
                      type="checkbox"
                      :checked="data.sign_req"
                      :disabled="data.isUploadRequest"
                      @change="handleChangeCheckBox($event, data, index)"
                    />
                  </td>
                  <td>
                    <span  v-if="data && data.document && !data.isCustomDocumentType  "
                      >
                    <CButton
                    class="btn-link mb-1 cursor-pointer p-0 text-left"
                      @click="$emit('preview', data.document)"
                    >
                  
                      {{ data.document_name }}
                     
                    </CButton>
                    
                        <input
                          :id="`custom-document-${data.document_library_id}-${index}`"
                          hidden
                          :name="`custom_document__${index}`"
                          type="file"
                          :ref="`file-ref-${data.document_library_id}-${index}`"
                          :accept="data.allowed_extensions | addPrefix"
                          @change="selectedFile($event, index)"
                        />
                        
                        <label
                         class=" ml-3 text-primary "
                          style="font-size: 14px;"
                          :for="`custom-document-${data.document_library_id}-${index}`"
                        >
                          <i class="fa-solid fa-edit cursor-pointer"></i>
                        </label>
                      
                      
                    </span>
                    
                    <span v-else-if="data.isCustomDocumentType">
                      <span
                        v-if="data.customDocument"
                        class="d-inline-block text-truncate"
                        style="max-width: 150px"
                        :title="data.document_name"
                        >{{ data.document_name }}</span
                      >
                      <span v-else>
                        <input
                          :id="`custom-document-${data.document_library_id}-${index}`"
                          hidden
                          :name="`custom_document__${index}`"
                          type="file"
                          :ref="`file-ref-${data.document_library_id}-${index}`"
                          :accept="data.allowed_extensions | addPrefix"
                          @change="selectedFile($event, index)"
                        />
                        <label
                          class="btn btn-primary"
                          :for="`custom-document-${data.document_library_id}-${index}`"
                        >
                          Upload
                        </label>
                      </span>
                    </span>
                    <p v-else>--</p>
                  </td>
                  <td>
                    <input
                      name="isSelected"
                      type="checkbox"
                      :checked="data.isSelected || false"
                      :disabled="checkDisable(data)"
                      @change="handleChangeCheckBox($event, data, index)"
                    />
                  </td>
                </tr>
                <tr v-if="!documentLibraries.length">
                  <td colspan="6">
                    <p class="text-muted h6 my-2 text-center">No Data Found</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="isOfferOnboard">
            <h6 class="border-top-primary pt-2"></h6>
            <ValidationObserver
              ref="offerOnboardForm"
              v-slot="{ handleSubmit }"
            >
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                name="offerOnboardForm"
              >
                <CRow>
                  <CCol md="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12">Candidate Joining Date</label>
                      <div class="col-lg-12">
                        <DatePicker
                          name="joining_date"
                          :value="actionData.joining_date"
                          @input="handleDateChange"
                          format="DD-MM-YYYY"
                          :showFromToday="true"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12 required">Alert Set For</label>
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="alert_days"
                            :value="actionData.alert_days"
                            :options="
                              options && options['alert_days']
                                ? options['alert_days']
                                : []
                            "
                            @change="handleChangeRadio"
                          />
                          <DatePicker
                            v-if="
                              payload.alert_days &&
                              payload.alert_days === 'custom'
                            "
                            name="expiry_date"
                            :value="actionData.expiry_date"
                            @input="handleDateChange"
                            :error="errors[0]"
                            format="DD-MM-YYYY"
                            :showFromToday="true"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6">
                    <CRow class="mb-3">
                      <label name="lbl_urgent" class="col-lg-12 col-md-12"
                        >Is Urgent?</label
                      >
                      <CCol md="12">
                        <RadioButton
                          name="is_urgent"
                          :value="actionData.is_urgent"
                          :options="
                            options && options['boolean']
                              ? options['boolean']
                              : []
                          "
                          @change="handleChangeRadio"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="8">
                    <CRow class="mb-3">
                      <div class="col-lg-12 col-md-12 d-flex pb-2">
                        <div>
                          <label class="text-nowrap pr-3 required"
                            >Comments</label
                          >
                        </div>
                        <div
                          class="w-100"
                          v-if="options['comments_options'].length"
                        >
                          <Select
                            name="comments_options"
                            @input="handleSelect"
                            :value="actionData.comments_options"
                            :options="
                              options && options['comments_options']
                                ? options['comments_options']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextAreaInput
                            ref="comments"
                            name="comments"
                            :value="actionData.comments"
                            label="comments"
                            @input="handleInput"
                            :error="errors[0]"
                          ></TextAreaInput>
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </CModal>
    </div>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      buttonLabel="Apply"
      @modalCallBack="cropperModalCallBack"
    />
  </div>
</template>
<script>
import CropperModal from "@/components/reusable/CropperModal";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import m from "moment";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
export default {
  name: "uploadDocumentAction",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    action_type: {
      type: String,
      default: null,
    },
    candidateDetails: {
      type: Object,
      default: null,
    },
    jobDetails: {
      type: Object,
      default: null,
    },
    actionInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CropperModal,
    Select,
    DatePicker,
    TextInput,
    TextAreaInput,
    RadioButton,
  },
  data() {
    return {
      isFetching: false,
      documentLibraries: [],
      isPhotoUpload: false,
      selectedFileRef: null,
      cropperModal: {
        fileData: {},
        isShowPopup: false,
        index: null,
      },
      actionData: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "customerDocumentLibrary",
      "actionComments",
      "getCandidateJoiningDate",
    ]),
    _documentLibraries() {
      return this.customerDocumentLibrary.map((v) => ({
        document_library_id: v?.document_library_id,
        type_name: v?.customer_document_type?.type_name,
        library_name: v?.library_name,
        document_name: v?.organisation_document?.document_name,
        link_to_doc: v?.organisation_document?.link_to_doc,
        request_type: v?.request_type,
        isCustomDocumentType: v?.request_type === "custom" || false,
        isEditableDocs:v?.request_type === "EditableDocs" || false,
        isUploadRequest: v?.request_type === "upload_request" || false,
        comments: v?.comments,
        organisation_document_id: v?.organisation_document_id,
        document: v?.organisation_document
          ? {
              document_name: v?.organisation_document?.document_name,
              document_ext: v?.organisation_document?.document_ext,
              link_to_doc: v?.organisation_document?.link_to_doc,
            }
          : null,
        sign_req: v?.sign_req,
      }));
    },
    isOfferOnboard() {
      return ["offer", "licensing"].includes(this.action_type) || false;
    },
    options() {
      return {
        comments_options: this.actionComments || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        alert_days: [
          { code: 1, label: "1 Day" },
          { code: 3, label: "3 Days" },
          { code: 7, label: "7 Days" },
          { code: "custom", label: "Customize" },
        ],
      };
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomerDocumentLib",
      "showToast",
      "fetchCandidateJoiningDate",
      "fetchCommentsfor_recruiter",
    ]),
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    handleChangeCheckBox(event, data, index) {
      const { checked, name } = event.target;
      this.documentLibraries[index][name] = checked;
    },
    handleDateChange(name, value) {
      Vue.set(this.actionData, name, value);
      Vue.set(
        this.payload,
        name,
        value ? m(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.actionData, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (name === "alert_days" && val != "custom") {
        this.handleDateChange("expiry_date", m().add(val, "d").toDate());
      }
    },
    handleSelect(name, value) {
      Vue.set(this.actionData, name, value);
      if (name === "comments_options")
        this.handleInput("comments", value.label);

      this.payload = {
        ...this.payload,
        [name]: value ? value?.id || value?.code || value : null,
      };
    },
    handleInput(name, value) {
      Vue.set(this.actionData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value,
      };
    },
    populateData() {
      this.documentLibraries =
        this.customerDocumentLibrary.map((v) => ({
          customer_document_type_id: v?.customer_document_type_id,
          document_library_id: v?.document_library_id,
          type_name: v?.customer_document_type?.type_name,
          library_name: v?.library_name,
          document_name: v?.organisation_document?.document_name,
          link_to_doc: v?.organisation_document?.link_to_doc,
          request_type: v?.request_type,
          isCustomDocumentType: v?.request_type === "custom" || false,
          isEditableDocs:v?.request_type === "EditableDocs" || false,
          isUploadRequest: v?.request_type === "upload_request" || false,
          comments: v?.comments,
          organisation_document_id: v?.organisation_document_id,
          document: v?.organisation_document
            ? {
                document_name: v?.organisation_document?.document_name,
                document_ext: v?.organisation_document?.document_ext,
                link_to_doc: v?.organisation_document?.link_to_doc,
              }
            : null,
          sign_req: v?.sign_req,
          allowed_extensions: this.toLowerCase(
            v?.customer_document_type?.allowed_extensions
          ),
        })) || [];
      if (this.isOfferOnboard) this.setDefaultAlertDate();
      return;
    },
    setDefaultAlertDate() {
      let alertDate = m().add(7, "d").toDate();
      this.handleDateChange("expiry_date", alertDate);
      this.handleChangeRadio("is_urgent", false);
      this.handleChangeRadio("alert_days", 7);
      if (this.getCandidateJoiningDate?.joining_date) {
        this.actionData["joining_date"] = m(
          this.getCandidateJoiningDate?.joining_date
        ).toDate();
      }
      return true;
    },
    toLowerCase(data, addPrefix = false) {
      if (data && Array.isArray(data))
        return (
          data.map((v) => `${addPrefix ? "." : ""}${v.toLowerCase()}`) || []
        );
      if (data) return `${addPrefix ? "." : ""}${data.toLowerCase()}`;
      return data;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1].toLowerCase();
    },
    selectedFile(event, index) {
      const documentLib = this.documentLibraries[index];
      const size = event.target.files[0]?.size;
      this.selectedFileRef = `file-ref-${documentLib?.document_library_id}-${index}`;
      if (Math.round(size / (1024 * 1024)) <= 5) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const allowed_extensions = documentLib.allowed_extensions;
        if (allowed_extensions.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.isShowPopup = true;
            this.cropperModal.fileData = file;
            this.cropperModal.index = index;
          } else {
            this.cropperModal.isShowPopup = false;
            const { name: file_name } = file;
            this.handleFileUploads({ file, file_name }, index);
          }
        } else {
          let fileTypeError = documentLib.allowed_extensions.join(", ");
          if (this.$refs[this.selectedFileRef])
            this.$refs[this.selectedFileRef].value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
        }
      } else {
        if (this.$refs[this.selectedFileRef])
          this.$refs[this.selectedFileRef].value = "";
        this.isPhotoUpload = false;
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
      }
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        if (this.$refs[this.selectedFileRef])
          this.$refs[this.selectedFileRef].value = "";
        this.cropperModal.fileData = {};
      } else {
        let { fileBlob, file_name } = data;
        const { index } = this.cropperModal;
        this.handleFileUploads({ file: fileBlob, file_name }, index);
      }
      this.cropperModal.isShowPopup = false;
    },
    handleFileUploads(data, index) {
      const { file_name, file } = data;
      Vue.set(this.documentLibraries[index], "customDocument", file);
      Vue.set(this.documentLibraries[index], "document_name", file_name);
    },
    checkDisable(data) {
      return data?.isCustomDocumentType ? !data.customDocument : false;
    },
    async onSubmit() {
      const isValid = await this.$refs?.offerOnboardForm?.validate();
      if (!isValid && this.isOfferOnboard) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
        return;
      }
      if (
        this.documentLibraries?.isSelected &&
        !this.documentLibraries?.customDocument
      ) {
        const { library_name } = this.documentLibraries;
        this.showToast({
          class: "bg-danger text-white",
          message: `please choose file for ${library_name}!`,
          autohide: 2000,
        });
        return;
      }
      const { job_id } = this.jobDetails;
      const { candidate_uid } = this.candidateDetails;
      const { organisation_id, status_id, sub_status_id } = this.actionInfo;
      const documentPayload =
        this.documentLibraries
          .filter(({ isSelected }) => isSelected)
          .map((v) => {
            let payload = {
              job_id,
              candidate_uid,
              organisation_id,
              action_type: this.action_type,
              actioned_by: "recruiter",
              action_required: v?.sign_req,
              comments: v?.comments,
              document_library_id: v?.document_library_id,
            };
            if (
              v?.customer_document_type_id &&
              v?.customDocument &&
              (v?.isCustomDocumentType ||  v?.isEditableDocs)
            )
              payload = {
                ...payload,
                customer_document_type_id: v?.customer_document_type_id,
                file: v?.customDocument,
                fileName: v?.document_name,
              };
            return payload;
          }) || [];
      if (!documentPayload.length) {
        this.showToast({
          class: "text-white bg-danger",
          message: "Please select atleast one Document",
        });
        return;
      }
      let processPayload = null;
      if (this.isOfferOnboard) {
        const { name } = this.$route;
        const isFromDashBoard = name === "Recruiter DashBoard";
        const statusPayload = {
          offer: {
            sub_status_id: 59,
            action_id: 7,
            filter_id: 28,
            tab_id: 4,
          },
          licensing: {
            sub_status_id: 69,
            action_id: 8,
            filter_id: 29,
            tab_id: 4,
          },
        };
        let payload = {
          current_status: status_id,
          current_sub_status: sub_status_id,
          expiry_date: this.payload?.expiry_date,
          comments: this.payload?.comments,
          isFromDashBoard,
        };
        if (statusPayload[this.action_type]) {
          payload = { ...payload, ...statusPayload[this.action_type] };
        } else {
          this.showToast({
            class: "text-white bg-danger",
            message: "Something went wrong. Please try again later",
          });
          return;
        }
        processPayload = { candidate_uid, job_id, payload };
      }
      let finalPayload = { documentPayload, processPayload };
      this.modalCallBack(true, finalPayload);
    },
  },
  mounted() {
    this.isFetching = true;
    const { candidate_type_id, candidate_uid } = this.candidateDetails;
    const { job_id } = this.jobDetails;
    const { status_id, sub_status_id } = this.actionInfo;
    let appendAction = [];
    appendAction = [
      ...appendAction,
      this.fetchCustomerDocumentLib({
        category: this.action_type,
        candidate_type_ids__overlap: `{${candidate_type_id}}`,
      }),
    ];
    if (this.isOfferOnboard) {
      appendAction = [
        ...appendAction,
        this.fetchCandidateJoiningDate({
          order_by: "-action_detail_id",
          date_field: "initiated_date",
          completed_by__isnull: true,
          skip: 0,
          limit: 1,
          action_id: 18,
          job_id,
          candidate_uid,
        }),
      ];
      appendAction = [
        ...appendAction,
        this.fetchCommentsfor_recruiter({ status_id, sub_status_id }),
      ];
    }
    Promise.all(appendAction).then(async (res) => {
      await this.populateData();
      this.isFetching = false;
    });
  },
  filters: {
    toPascal(data) {
      if (data) return _.startCase(data);
      return data;
    },
    addPrefix(data) {
      if (data) return data.map((v) => `.${v}`).join(",");
      return data;
    },
  },
};
</script>