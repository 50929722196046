<template>
  <div class="inview-assessment">
    <div class="d-none" v-if="downloadFeedback">
      <InterviewFeedBack
        :feedBackInfo="downloadFeedback"
        ref="interviewFeedBackDownload"
      />
    </div>
    <div class="table-responsive">
      <table class="table table-striped w-100">
        <thead>
          <tr>
            <th>Interviewer</th>
            <th>Document Name</th>
            <th>Date Uploaded</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(feedBackInfo, index) in interviewFeedBacks"
            :key="`feedback_${index}`"
          >
            <td>
              <span>{{ feedBackInfo.interviewer }}</span>
              <span
                v-if="feedBackInfo.unique_url"
                class="ml-2 cursor-pointer"
                v-c-tooltip="{
                  content: 'Click to Copy IEF URL',
                  placement: 'left',
                  appendToBody: true,
                }"
                @click="copyURL(`ief_url_${feedBackInfo.external_user_id}`)"
              >
                <i class="fa-solid fa-circle-info text-primary" />
                <input
                  type="hidden"
                  :id="`ief_url_${feedBackInfo.external_user_id}`"
                  :value="feedBackInfo.unique_url"
                />
              </span>
            </td>
            <td>
              <div v-if="feedBackInfo.interviewFeedback">
                <CButton
                  colour="primary"
                  class="btn-link p-0"
                  :disabled="!feedBackInfo.interviewFeedback.completed"
                  @click="previewIEF(feedBackInfo.interviewFeedback)"
                  >Interview Evaluation Form</CButton
                >
                <CButton
                  :disabled="!feedBackInfo.interviewFeedback.completed"
                  @click="directDownloadIEF(feedBackInfo.interviewFeedback)"
                  v-c-tooltip="'Click to Download File'"
                >
                  <i class="fas fa-download ml-1 text-primary"></i>
                </CButton>
              </div>
              <span v-else>--</span>
            </td>
            <td>
              <div v-if="feedBackInfo.interviewFeedback">
                {{ feedBackInfo.interviewFeedback | dateUploaded }}
              </div>
              <span v-else>--</span>
            </td>
            <td>
              <div v-if="feedBackInfo.interviewFeedback">
                {{
                  feedBackInfo.interviewFeedback.completed
                    ? "Completed"
                    : "In-Process"
                }}
              </div>
              <span v-else>--</span>
            </td>
          </tr>
          <tr v-if="!interviewFeedBacks.length">
            <td colspan="7">
              <p class="text-muted text-center h6 mt-2">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CModal
      v-if="IEFModal.isShowPopup"
      color="primary"
      :show.sync="IEFModal.isShowPopup"
      :close-on-backdrop="false"
      size="xl"
    >
      <template #header>
        <h6 class="modal-title">Interview Evaluation Form</h6>
        <CButtonClose @click="IEFmodalCallBack(false)" class="text-black" />
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex py-3 mx-3">
          <div class="flex-grow-1 d-flex">
            <a @click="generatePDF" v-c-tooltip="'Click to Download File'"
              ><i class="fas fa-download ml-1 text-primary"></i
            ></a>
          </div>

          <div class="d-flex">
            <CButton
              type="button"
              variant="outline"
              color="primary"
              class="px-4"
              @click="IEFmodalCallBack(false)"
              >Close</CButton
            >
          </div>
        </div>
      </template>
      <div>
        <InterviewFeedBack
          :feedBackInfo="IEFModal.feedBackInfo"
          ref="interviewFeedBack"
          :showPrint="true"
        />
      </div>
    </CModal>
  </div>
</template>
<script>
import InterviewFeedBack from "@/components/reusable/InterviewFeedBack";
import m from "moment";
import { mapActions } from 'vuex';
export default {
  name: "interviewAssessment",
  props: {
    interviewFeedBacks: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    InterviewFeedBack,
  },
  data() {
    return {
      IEFModal: {
        isShowPopup: false,
        feedBackInfo: null,
      },
      downloadFeedback: null,
    };
  },
  methods: {
    ...mapActions(["showToast"]),
    copyURL(id) {
      let testingCodeToCopy = document.getElementById(id);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        this.showToast({
          message: `URL copied ${msg}`,
          class: `text-white ${
            msg === "successful" ? "bg-success" : "bg-danger"
          }`,
        });
      } catch (err) {
        this.showToast({
          message: `Unable to Copy`,
          class: `text-white bg-danger`,
        });
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    previewIEF(data) {
      this.IEFModal.feedBackInfo = data;
      this.IEFModal.isShowPopup = true;
    },
    IEFmodalCallBack() {
      this.IEFModal.isShowPopup = false;
      this.IEFModal.feedBackInfo = null;
    },
    directDownloadIEF(data) {
      this.downloadFeedback = data;
      setTimeout(() => {
        this.$refs?.interviewFeedBackDownload?.generateReport();
      }, 100);
    },    
    generatePDF() {
      this.$refs.interviewFeedBack.generateReport();
    },
  },
  filters:{
    dateUploaded(data) {
      if (data?.last_modified_on)
        return m
          .utc(data?.last_modified_on)
          .local()
          .format("DD-MMM-YYYY hh:mm A");
      else if (data?.created_on)
        return m.utc(data?.created_on).local().format("DD-MMM-YYYY hh:mm A");
    },
  }
};
</script>